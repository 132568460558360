import React, {useState, useEffect, useMemo} from 'react';
import {useAuthStatus} from 'gatsby-plugin-google-gapi';
import {Row} from 'react-bootstrap';
import {InputGroup, FormControl, Button, Carousel} from 'react-bootstrap';
import axios from 'axios';
import {Link} from 'gatsby';
import Modal from 'react-modal';
import Select from 'react-select';

import imgHeader from '../images/header/section.svg';
import iconArrow from '../images/icon/Arrow_right_long.svg';
import imgSection from '../images/layout/layout1.svg';
import imgSection2 from '../images/layout/layout2.svg';
import imgSection3 from '../images/layout/layout3.svg';
import iconLocation from '../images/icon/location-pin.svg';
import iconMessage from '../images/icon/message_alt_fill.svg';
import iconPhone from '../images/icon/phone.svg';
import iconPin from '../images/icon/pin_fill.svg';
import imgCheckValid from '../images/icon/Check_round_fill.svg';
import imgcheclInvalid from '../images/icon/Close_round_fill.svg';
import imgSlide1 from '../images/slide/img-slide1.png';
import imgSlide2 from '../images/slide/img-slide2.png';
import imgSlide3 from '../images/slide/img-slide3.jpg';
import imgSlide4 from '../images/slide/img-slide4.png';
import imgLoading from '../images/icon/icon-loading2.svg';
import imgform from '../images/body/form-submit.svg';

Modal.setAppElement('#___gatsby');
const LandingPage = () => {
  const [isShowing, setIsShowing] = useState(false);
  const {authed} = useAuthStatus();
  console.log('authed', authed);
  const Modals = () => {
    return (
      <Modal
        className="modal modal-submit"
        id="modal-submit"
        isOpen={isShowing}
        onRequestClose={closeModal}
      >
        <div className="modal-content">
          <div className="modal-img">
            <img src={imgform} alt="" />
          </div>
        </div>
      </Modal>
    );
  };
  const closeModal = (e) => {
    e.preventDefault();
    setIsShowing(!isShowing);
    document.documentElement.style.overflowY = 'auto';
    window.location.reload();
  };
  const openModal = () => {
    setIsShowing(!isShowing);
    document.documentElement.style.overflowY = 'hidden';
  };
  const UploadFile = (e) => {
    e.preventDefault();
    let file = document.getElementsByName('upFile')[0].files[0]; // file
    if (file) {
      setIsLoading(true);
      var metadata = {
        name: file.name,
        mimeType: file.type,
        parents: ['1FD52-Ee6c2EWiv-8jSGgqhrqTWWwmFZY'], // Please set folderId here.
      };
      var form = new FormData();
      form.append(
        'metadata',
        new Blob([JSON.stringify(metadata)], {type: 'application/json'}),
      );
      form.append('file', file);
      fetch(
        'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
        {
          method: 'POST',
          headers: new Headers({
            Authorization: 'Bearer ' + window.gapi.auth.getToken().access_token,
          }),
          body: form,
        },
      )
        .then((res) => {
          return res.json();
        })
        .then(function (val) {
          if (val) {
            setCv(`https://drive.google.com/file/d/${val.id}/view`);
            setNameCv(val.name);
            if (val) {
              setIsLoading(false);
              document
                .getElementById('valid-input')
                .classList.remove('is-invalid');
            }
          }
        });
    }
  };

  const options = [
    {value: 'AI Engineer', label: 'AI Engineer'},
    {value: 'Software Engineer', label: 'Software Engineer'},
    {value: 'Embedded Engineer', label: 'Embedded Engineer'},
    {value: 'Hardware Engineer', label: 'Hardware Engineer'},
  ];
  const [isDidMount, setIsDidMount] = useState(false);
  useEffect(() => {
    setIsDidMount(true);
  }, []);
  const MapLeaflet = useMemo(() => {
    const position = [10.850140342106446, 106.80574158879931];
    if (isDidMount) {
      var {MapContainer, Marker, TileLayer} = require('react-leaflet');
      const L = require('leaflet');
      const iconMap = L.icon({
        iconUrl: iconLocation,
        iconSize: [27, 43], // size of the icon
        iconAnchor: [22, 47], // point of the icon which will correspond to marker's location
        popupAnchor: [-3, -76],
        shadowSize: [68, 95], // the same for the shadow
        shadowAnchor: [22, 94], //point from which the popup should open relative to the iconAnchor
      });
      return (
        <MapContainer
          center={position}
          zoom={15}
          scrollWheelZoom={false}
          style={{width: 623, height: 400}}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position} icon={iconMap}></Marker>
        </MapContainer>
      );
    } else {
      return null;
    }
  }, [isDidMount]);
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [vacancies, setVacancies] = useState('');

  const [major, setMajor] = useState('');
  const [fullnameErr, setFullNameErr] = useState({});
  const [emailErr, setEmailErr] = useState({});
  const [phoneErr, setPhoneErr] = useState({});
  const [vacanciesErr, setVacanciesErr] = useState({});
  const [cv, setCv] = useState('');
  const [nameCv, setNameCv] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const FullNameValidation = (fieldName, fieldValue) => {
    const fullnameErr = {};
    if (
      fieldName.trim() === '' ||
      /[^a-zA-Z -]/.test(fieldValue) ||
      fieldValue.trim().length < 3
    ) {
      fullnameErr.isValid = false;
      setFullNameErr(fullnameErr);
    }
    setFullNameErr(fullnameErr);
    return null;
  };
  const EmailValidation = (email) => {
    const emailErr = {};
    const regexp =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.trim() === '') {
      emailErr.isValid = false;
    }
    if (regexp.test(email) === false) {
      emailErr.isValid = false;
      setEmailErr(emailErr);
    }
    setEmailErr(emailErr);
    return null;
  };
  const PhoneValidation = (phone) => {
    const regexp = /^\d{10}$/;
    const phoneErr = {};
    if (regexp.test(phone) === false) {
      phoneErr.isValid = false;
      setPhoneErr(phoneErr);
    }
    if (phone.trim() === '') {
      phoneErr.isValid = false;
    }
    setPhoneErr(phoneErr);
    return null;
  };
  const VacanciesValidation = () => {
    const vacanciesErr = {};
    if (vacancies.trim().length === 0) {
      vacanciesErr.isValid = false;
      setVacanciesErr(vacanciesErr);
    }
    document.getElementById('select-vacancies').classList.remove('is-invlaid');
    return null;
  };
  const FormValidation = () => {
    let isValid;
    if (Object.keys(fullnameErr).length > 0) {
      isValid = fullnameErr.isValid;
    }
    if (Object.keys(emailErr).length > 0) {
      isValid = emailErr.isValid;
    }
    if (Object.keys(phoneErr).length > 0) {
      isValid = phoneErr.isValid;
    }
    if (fullname.trim().length === 0) {
      document.getElementById('fullname').focus();
      isValid = false;
    }
    if (email.trim().length === 0) {
      document.getElementById('email').focus();
      isValid = false;
    }
    if (phoneNumber.trim().length === 0) {
      document.getElementById('phone').focus();
      isValid = false;
    }
    if (vacancies.trim().length === 0) {
      document.getElementById('select-vacancies').classList.add('is-invalid');
      isValid = false;
    }
    if (cv.trim().length === 0) {
      document.getElementById('valid-input').classList.add('is-invalid');
    }
    document.getElementById('major').focus();
    return isValid;
  };
  const [formModel, setFormModel] = useState({});
  useEffect(() => {
    setFormModel({
      timestamp: new Date().toLocaleString(),
      fullName: fullname,
      email: email,
      phoneNumber: phoneNumber,
      vacancies: vacancies,
      major: major,
      linkCv: cv,
    });
    if (vacancies.trim().length > 0) {
      document
        .getElementById('select-vacancies')
        .classList.remove('is-invalid');
    }
  }, [fullname, email, phoneNumber, vacancies, major, cv]);
  function OnSubmit(e) {
    e.preventDefault();
    const isValid = FormValidation();
    if (isValid === undefined) {
      setFormModel({
        timestamp: new Date().toLocaleString(),
        fullName: fullname,
        email: email,
        phoneNumber: phoneNumber,
        vacancies: vacancies,
        major: major,
        linkCv: cv,
      });
      console.log('setForm', formModel);
      if (Object.keys(formModel).length > 0) {
        axios
          .post(
            'https://sheet.best/api/sheets/47a2c179-dc2f-4fc6-9f35-87eda4ad2750',
            formModel,
          )
          .then((response) => {
            if (response.status === 200) {
              setFullname('');
              setEmail('');
              setPhoneNumber('');
              setFullNameErr({});
              setEmailErr({});
              setPhoneErr({});
              setFormModel({});
              document.getElementById('phone').innerText = '';
              document.getElementById('email').innerText = '';
              document.getElementById('fullname').innerText = '';
              document.getElementById('major').innerText = '';
              document.getElementById('link-cv').innerText = '';
              openModal();
            }
          });
      }
    }
  }
  const UploadButton = (e) => {
    e.preventDefault();
    document.getElementById('customFile').click();
  };
  useEffect(() => {
    setInterval(() => {
      document
        .getElementById('btn-register')
        .classList.add('wobble-hor-bottom');
    }, 2000);
    setInterval(() => {
      document
        .getElementById('btn-register')
        .classList.remove('wobble-hor-bottom');
    }, 2500);
  }, []);
  return (
    <div>
      <article>
        <section className="header-page" id="home-page">
          <div className="header-page-wrap container-wrap">
            <div className="header-left">
              <div className="header-left-txt">
                <span>Khởi động chương trình</span>
              </div>
              <div className="title-header">
                <h2>“ƯƠM MẦM DEV” - Mùa 2</h2>
              </div>
              <div className="sub-title-header">
                <p>
                  Chương trình nằm trong chuỗi hoạt động ươm mầm tài năng trẻ
                  thuộc Công ty UniCloud dành riêng cho các bạn sinh viên khối
                  ngành Công nghệ thông tin.
                </p>
              </div>
              <div className="btn-register">
                <button className="btn-register-wrap " id="btn-register">
                  <Link to="#form-careers">
                    <h3>ỨNG TUYỂN NGAY</h3>
                  </Link>
                </button>
              </div>
            </div>
            <div className="header-right">
              <img src={imgHeader} alt="" loading="eager" />
            </div>
          </div>
        </section>
      </article>
      <article>
        <section className="form-submit" id="form-careers">
          <div className="form-main container-wrap">
            <div className="form-main-wrap">
              <h3 className="title">Form ứng tuyển</h3>
              <form
                id="careers"
                onSubmit={(e) => {
                  OnSubmit(e);
                  return false;
                }}
                noValidate
                method="post"
              >
                <div className="item-form">
                  <InputGroup
                    className={
                      Object.keys(fullnameErr).length > 0 ? 'is-invalid' : ''
                    }
                  >
                    <FormControl
                      placeholder="Họ và tên"
                      aria-label="Họ và tên"
                      aria-describedby="basic-addon2"
                      className="item-input"
                      name="fullname"
                      onChange={(e) => setFullname(e.target.value)}
                      onBlur={(e) =>
                        FullNameValidation(e.target.name, e.target.value)
                      }
                      autoComplete="off"
                      required
                      value={fullname}
                      id="fullname"
                    />
                    {fullname.trim().length > 0 &&
                      Object.keys(fullnameErr).length === 0 && (
                        <img
                          className="isValid"
                          src={imgCheckValid}
                          alt="icon valid"
                        />
                      )}
                    {Object.keys(fullnameErr).length > 0 && (
                      <img
                        className="isValid"
                        src={imgcheclInvalid}
                        alt="icon check invalid"
                      />
                    )}
                  </InputGroup>
                </div>
                <div className="item-form">
                  <InputGroup
                    className={
                      Object.keys(emailErr).length > 0 ? 'is-invalid' : ''
                    }
                  >
                    <FormControl
                      placeholder="E-mail"
                      aria-label="E-mail"
                      aria-describedby="basic-addon2"
                      className="item-input"
                      name="email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      onBlur={(e) => EmailValidation(e.target.value)}
                      autoComplete="off"
                      value={email}
                      id="email"
                    />
                    {email.trim().length > 0 &&
                      email.trim().length > 0 &&
                      Object.keys(emailErr).length === 0 && (
                        <img
                          className="isValid"
                          src={imgCheckValid}
                          alt="icon valid"
                        />
                      )}
                    {Object.keys(emailErr).length > 0 && (
                      <img
                        className="isValid"
                        src={imgcheclInvalid}
                        alt="icon check invalid"
                      />
                    )}
                  </InputGroup>
                </div>
                <div className="item-form">
                  <InputGroup
                    className={
                      Object.keys(phoneErr).length > 0 ? 'is-invalid' : ''
                    }
                  >
                    <FormControl
                      placeholder="Số điện thoại"
                      aria-label="Số điện thoại"
                      aria-describedby="basic-addon2"
                      className="item-input"
                      name="phone"
                      required
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      onBlur={(e) => PhoneValidation(e.target.value)}
                      autoComplete="off"
                      value={phoneNumber}
                      id="phone"
                    />
                    {phoneNumber.trim().length > 0 &&
                      Object.keys(phoneErr).length === 0 && (
                        <img
                          className="isValid"
                          src={imgCheckValid}
                          alt="icon valid"
                        />
                      )}
                    {Object.keys(phoneErr).length > 0 && (
                      <img
                        className="isValid"
                        src={imgcheclInvalid}
                        alt="icon check invalid"
                      />
                    )}
                  </InputGroup>
                </div>
                <div className="item-form">
                  <InputGroup className="">
                    <FormControl
                      placeholder="Ngành tốt nghiệp đại học"
                      aria-label="Ngành tốt nghiệp đại học"
                      aria-describedby="basic-addon2"
                      className="item-input"
                      onChange={(e) => setMajor(e.target.value)}
                      onBlur={(e) => setMajor(e.target.value)}
                      value={major}
                      id="major"
                    />
                  </InputGroup>
                </div>
                <div className="item-form ">
                  <Select
                    placeholder="Vị trí ứng tuyến"
                    options={options}
                    className={`react-select-container ${
                      Object.keys(vacanciesErr).length > 0 ? 'is-invalid' : ''
                    }`}
                    classNamePrefix="react-select"
                    onChange={(newValue) => setVacancies(newValue.value)}
                    onBlur={() => VacanciesValidation()}
                    id="select-vacancies"
                    defaultValue={vacancies}
                  />
                </div>
                <div className="item-form">
                  <InputGroup className="valid-input" id="valid-input">
                    <FormControl
                      placeholder="Upload CV"
                      aria-label="Upload CV"
                      aria-describedby="basic-addon2"
                      className="upload-cv-input"
                      value={nameCv}
                      id="link-cv"
                      readOnly
                    />
                    {isLoading && (
                      <img src={imgLoading} alt="" className="icon-loading" />
                    )}
                    <Button
                      id="upload-cv"
                      className="upload-cv"
                      onClick={(e) => UploadButton(e)}
                    >
                      <img src={iconArrow} alt="" />
                    </Button>
                  </InputGroup>
                </div>
                <div className="item-form">
                  <input
                    onChange={(e) => UploadFile(e)}
                    type="file"
                    className="form-control upload-cv-form"
                    name="upFile"
                    id="customFile"
                    accept="application/pdf,application/msword"
                  />
                </div>
                <div className="btn-register">
                  <button
                    className="btn-register-wrap jello-horizontal"
                    name="submit"
                    type="submit"
                    formNoValidate
                  >
                    <h3>ỨNG TUYỂN NGAY</h3>
                  </button>
                </div>
              </form>
              <Modals />
            </div>
          </div>
          <div className="form-main-right">
            <div className="main-right-image">
              <img src={imgSection} alt="" />
            </div>
          </div>
        </section>
      </article>
      <article>
        <section id="" className="banner-body">
          <div className="banner-body-wrap">
            <div className="banner-title focus-in-contract-bck">
              <h3>Quy trình tuyển dụng</h3>
            </div>
            <div className="banner-card container-wrap container">
              <div className="card-item wobble-hor-bottom" id="card-1">
                <p id="card-item-1">Nhận CV ứng tuyển</p>
                <div className="subtitle" id="subtitle-1">
                  <span>1</span>
                </div>
              </div>
              <div className="card-item wobble-hor-bottom" id="card-2">
                <p id="card-item-2">
                  Đánh giá mức độ phù hợp qua bài Test năng lực
                </p>
                <div className="subtitle" id="subtitle-2">
                  <span>2</span>
                </div>
              </div>
              <div className="card-item wobble-hor-bottom" id="card-3">
                <p id="card-item-3">Gặp gỡ phỏng vấn cùng Ban chuyên môn</p>
                <div className="subtitle" id="subtitle-3">
                  <span>3</span>
                </div>
              </div>
              <div className="card-item wobble-hor-bottom" id="card-4">
                <p id="card-item-4">Nhận offer và đi làm</p>
                <div className="subtitle" id="subtitle-4">
                  <span>4</span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </article>
      <article>
        <section id="vacancies" className="vacancies-page">
          <div className="vacancies-title">
            <h3 className="vacancies-title">Các vị trí tuyển dụng</h3>
          </div>
          <Row className="vacancies-body container-wrap" noGutters>
            <div className="vacancies-group" id="vacancies-group-1" xs={12}>
              <div className="vacancies-group-wrap  container-wrap">
                <div className="vacancies-item slide-br" id="vacancies-item-1">
                  <div className="vacancies-item-header">
                    <h4>AI Engineer</h4>
                  </div>
                  <div className="vacancies-item-body">
                    <p>Yêu cầu công việc:</p>
                    <ul>
                      <li>
                        Đưa ra các giải pháp AI/ML giải quyết vấn đề từ đơn giản
                        đến phức tạp.
                      </li>
                      <li>
                        Sử dụng và bổ sung các thư viện để xây dựng mô hình AI
                        đặc biệt trên các hệ thống nhúng.
                      </li>
                      <li>
                        Hiểu rõ các kiến trúc DL/ML để tối ưu trên nguồn tài
                        nguyên hạn chế.{' '}
                      </li>
                      <li>
                        Khai phá, giám sát và thu thập thêm dữ liệu nếu cần
                        thiết.
                      </li>
                      <li>
                        Tận dụng các nguồn dữ liệu có sẵn để huấn luyện các mô
                        hình.
                      </li>
                      <li>
                        Xây dựng quy trình và khai phá các đặc trưng cần thiết
                        từ bộ dữ liệu.
                      </li>
                      <li>Ứng dụng các kỹ thuật làm giàu dữ liệu.</li>
                      <li>
                        Phát triển các hệ thống giám sát và phân tích dữ liệu để
                        đánh giá hiệu năng và độ chính xác của mô hình.
                      </li>
                      <li>
                        Thường xuyên cập nhật những papers mới liên quan để đưa
                        ra những ý tưởng mới phù hợp hơn.
                      </li>
                      <li>Huấn luyện và tinh chỉnh các tham số cho mô hình.</li>
                      <li>Triển khai các mô hình lên các nền tảng đám mây.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="vacancies-group" id="vacancies-group-2" xs={12}>
              <div className="vacancies-group-wrap  container-wrap">
                <div className="vacancies-item slide-bl " id="vacancies-item-2">
                  <div className="vacancies-item-header">
                    <h4>Software Engineer</h4>
                  </div>
                  <div className="vacancies-item-body">
                    <p>Yêu cầu công việc(Backend, Frontend, Mobile, Devops):</p>
                    <ul>
                      <li>
                        Phát triển các dịch vụ Backend cho ứng dụng Mobile.
                      </li>
                      <li>
                        Phát triển ứng dụng quản lý tập đoàn, ứng dụng Backend
                        cho IoT, ứng dụng Realtime.
                      </li>
                      <li>
                        Thu thập và xử lý các yêu cầu thiết kế và kỹ thuật.
                      </li>
                      <li>
                        Tham gia vào quá trình phân tích và thiết kế hệ thống
                        cho các yêu cầu phát triển sản phẩm của công ty.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="vacancies-group" id="vacancies-group-3" xs={12}>
              <div className="vacancies-group-wrap  container-wrap">
                <div className="vacancies-item slide-tr" id="vacancies-item-3">
                  <div className="vacancies-item-header">
                    <h4>Embedded Engineer</h4>
                  </div>
                  <div className="vacancies-item-body">
                    <p>Yêu cầu công việc:</p>
                    <ul>
                      <li>
                        Phát triển firmware trên các thiết bị IoT trong hệ sinh
                        thái smart-living của Sunshine.
                      </li>
                      <li>
                        Xây dựng các thư viện, framework có thể sử dụng lại.
                      </li>
                      <li>Tối ưu hoá hiệu năng các ứng dụng.</li>
                      <li>Kiến trúc viết mã một cách chuyên nghiệp.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="vacancies-group" id="vacancies-group-4" xs={12}>
              <div className="vacancies-group-wrap  container-wrap">
                <div className="vacancies-item slide-tl" id="vacancies-item-4">
                  <div className="vacancies-item-header">
                    <h4>Hardware Engineer</h4>
                  </div>
                  <div className="vacancies-item-body">
                    <p>Yêu cầu công việc:</p>
                    <ul>
                      <li>Hoàn thành các yêu cầu trong quá trình đào tạo.</li>
                      <li>
                        Cùng nhóm hardware và firmware phát triển các sản phẩm
                        cho hệ sinh thái Smart Living của tập đoàn Sunshine
                        Group.
                      </li>
                      <li>
                        Cải tiến các thiết bị cũ, nghiên cứu các thiết bị mới.
                      </li>
                      <li>
                        Xây dựng thư viện thiết kế (Schematic, footprint).
                      </li>
                      <li>
                        Xây dựng giải pháp, thiết kế shematic, pcb cho các thiết
                        bị smarthome.
                      </li>
                      <li>
                        Liên kết cùng các nhà phân phối linh kiện, hỗ trợ sản
                        xuất.
                      </li>
                      <li>
                        Làm việc cùng đội ngũ vận hành sản xuất, các đối tác gia
                        công.
                      </li>
                      <li>Báo cáo trực tiếp công việc cho trưởng nhóm.</li>
                      <li>Được đào tạo để phát triển chuyên môn.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </section>
        <section className="welfare-card" id="welfare">
          <div className="vacancies-item slide-br" id="vacancies-item-4">
            <div className="vacancies-item-header">
              <h4>Phúc lợi</h4>
            </div>
            <div className="vacancies-item-body">
              <p>Quyền lợi:</p>
              <ul>
                <li>
                  Thực tập có lương (Mức hỗ trợ xứng đáng với năng lực, từ
                  2.000.000đ - 4.000.000/tháng)
                </li>
                <li>
                  Thời gian linh hoạt (Thoải mái thời gian có mặt trên công ty
                  tối thiểu 24h/tuần).
                </li>
                <li>Môi trường năng động, chuyên nghiệp.</li>
                <li>Trở thành nhân viên chính thức của công ty sau 3 tháng.</li>
                <li>
                  Được đào tạo nâng cao năng lực bằng đội ngũ Mentor có tâm – có
                  tầm.
                </li>
                <li>
                  Tham gia vào các dự án tầm cỡ áp dụng nhiều công nghệ mới.
                </li>
              </ul>
              <br />
              <p>Yêu cầu:</p>
              <ul>
                <li>
                  Các bạn sinh viên năm 3/ năm cuối/ đang làm đồ án tốt nghiệp
                  hoặc sắp tốt nghiệp khối CNTT với học lực khá – giỏi.
                </li>
                <li>
                  Nhanh nhẹn, thích ứng linh hoạt với các thay đổi, không ngại
                  thử thách.
                </li>
              </ul>
            </div>
          </div>
          <div className="section-layout-bg">
            <img src={imgSection2} alt="" />
          </div>
        </section>
      </article>
      <article>
        <section id="about-us" className="about-us container-wrap">
          <div className="about-us-wrap">
            <div className="about-us-title">
              <h3>Về UniCloud</h3>
            </div>
            <div className="about-us-content">
              <div className="conten-slides">
                <Carousel autoPlay={false} nextIcon="" nextLabel="">
                  <Carousel.Item>
                    <div className="img-slide">
                      <img
                        className="d-block"
                        src={imgSlide1}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="img-slide">
                      <img
                        className="d-block"
                        src={imgSlide2}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="img-slide">
                      <img
                        className="d-block"
                        src={imgSlide3}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="img-slide">
                      <img
                        className="d-block"
                        src={imgSlide4}
                        alt="First slide"
                      />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="content-description">
                <p>
                  Được thành lập từ năm 2016, UniCloud là công ty công nghệ
                  chuyên về các giải pháp công nghệ IoT liên quan tới tối ưu và
                  nâng cao chất lượng đời sống của cư dân tại các dự án căn hộ
                  hạng sang. Với tiên chỉ đó, UniCloud không ngừng phát triển và
                  cho ra đời các giải pháp như giải pháp tổng thể cho smart
                  living, giải pháp nhà thông minh, giải pháp bãi đỗ xe thông
                  minh, giải pháp an ninh thông minh, giải pháp thang máy thông
                  minh, giải pháp kiểm soát ra vào thông minh và còn nhiều giải
                  pháp nữa sắp tới. Để kiểm soát và đảm bảo chất lượng sản phẩm
                  cũng như dịch vụ tới từng cư dân, khách hàng, tại UniCloud,
                  chúng tôi lắng nghe khách hàng, và theo đó cùng với đội ngũ kỹ
                  sư kỹ thuật trẻ trung, sáng tạo hơn 50 người được lãnh đạo bởi
                  những con người giàu kiến thức, kinh nghiệm và tâm huyết trong
                  lĩnh vực để làm ra sản phẩm hoàn thiện cả phần cứng cũng như
                  phần mềm từ khâu lên thiết kế ý tưởng sản phẩm, thiết kế mẫu
                  sản phẩm gồm vẽ mạch, viết firmware và viết app cho tới test
                  kiểm sản phẩm kỹ lưỡng trên các mô hình thực tế, và cuối cùng
                  là cho ra đời các sản phẩm hoàn thiện sản xuất hàng loạt tại
                  nhà máy được kiểm soát chặt chẽ của chúng tôi.
                </p>
                <br />
                <p>Những lĩnh vực phát triển của công ty:</p>
                <ul>
                  <li>
                    Xây dựng các ứng dụng di động (Mobile App) cho việc giám
                    sát, điều khiển, ...
                  </li>
                  <li>Thiết kế phần cứng của Sản phẩm.</li>
                  <li> Sản xuất Sản phẩm. </li>
                  <li>Xây dựng Firmware, Cloud Server, ...</li>
                  <li>Xây dựng Mobile App, Website, ...</li>
                  <li>AI, Machine learning, …</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </article>
      <hr className="hr-bottom container-wrap" />
      <footer className="footer-main">
        <div className="footer-main-wrap container-wrap container">
          <div className="footer-left ">{MapLeaflet}</div>
          <div className="footer-right ">
            <div className="footer-right-group">
              <div className="footer-logo">
                <img src="../../logo.png" alt="" height="60px" />
              </div>
              <div className="footer-content">
                <div className="footer-content-item">
                  <img src={iconPhone} alt="" /> <span>028 7107 9995</span>
                </div>
                <div className="footer-content-item footer-content-item__email">
                  <img src={iconMessage} alt="" />{' '}
                  <span>hcm.stech@sunshinemail.vn</span>
                </div>
                <div className="footer-content-item">
                  <img src={iconPin} alt="" />{' '}
                  <span>
                    Block 5, Lô I-3B-1, Đường N6, Khu công nghệ cao, Phường Tân
                    Phú - TP Thủ Đức - TP Hồ Chí Minh.
                  </span>
                </div>
                <div className="form-email">
                  <div className="form-email-wrap">
                    <p>
                      Giữ liên lạc với chúng tôi để cập nhật những thông tin mới
                      nhất về các vị trí ứng tuyển
                    </p>
                    <div className="item-form">
                      <InputGroup>
                        <FormControl
                          placeholder="E-mail"
                          aria-label="E-mail"
                          aria-describedby="basic-addon2"
                          className="email-input"
                        />
                        <Button id="email" className="btn-email">
                          <img src={iconArrow} alt="" />
                        </Button>
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-layout3 ">
          <img src={imgSection3} alt="" />
        </div>
      </footer>
    </div>
  );
};
export default LandingPage;
